import React, { useEffect } from 'react';
import { Row, Col, Pagination } from 'antd';
import Container from '../components/Container';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import CustomPaginationItem from '../components/CustomPaginationItem';
import { hashSectionScrollIntoView, useSearch } from '../utils/common';
import SubscribeBanner from '../components/SubscribeBanner';

// eslint-disable-next-line react/prop-types
function Article({ title, cover, description, href, id }) {
  const search = useSearch();
  const linkStr = href + search;
  return (
    <Row className="article" gutter={30} id={`section-${id}`}>
      <Col lg={{ span: 10 }} xs={{ span: 24 }}>
        <a href={linkStr} target="_blank" rel="noreferrer noopener nofollow">
          <img alt="cover" src={cover} className="img-fluid" />
        </a>
      </Col>
      <Col className="articleDesc" lg={{ span: 14 }} xs={{ span: 24 }}>
        <div className="content">
          <div className="title">
            <a href={linkStr} target="_blank" rel="noreferrer noopener">
              {title}
            </a>
          </div>
          <div className="description">{description}</div>
        </div>
        <div className="bottomSection">
          <div className="left">
            {/*<div className="author">作者：{author}</div>*/}
            {/*<div className="date">{date}</div>*/}
          </div>
          <div className="right">
            <a href={linkStr} target="_blank" rel="noreferrer noopener">
              视频详情
            </a>
          </div>
        </div>
      </Col>
    </Row>
  );
}

// eslint-disable-next-line react/prop-types
const CcMeaningful = ({ pageContext }) => {
  // eslint-disable-next-line react/prop-types
  const { dataList = [], total = 0, currentPage = 1, pageSize = 5 } = pageContext;

  useEffect(() => {
    hashSectionScrollIntoView();
  }, []);

  return (
    <Layout current="blogs" subCurrent="ccmeaningful">
      <SubscribeBanner backgroundImage="ccmeaningful-banner" />
      <section className="resources">
        <Container>
          {dataList.map((node) => (
            <Article key={node.id} {...node} />
          ))}
          <div className="pagiContainer">
            <Pagination
              total={total}
              pageSize={pageSize}
              hideOnSinglePage
              showSizeChanger={false}
              current={currentPage}
              responsive
              itemRender={(page, type, originalElement) => (
                <CustomPaginationItem
                  page={page}
                  type={type}
                  originalElement={originalElement}
                  hrefTag="ccmeaningful"
                />
              )}
            />
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export default CcMeaningful;
